<!-- eslint-disable vue/no-v-html -->
<template>
<div class="slamdown">
    <div class="hero">
        <NuxtImg src="/images/slamdown/slamdown-logo.png" alt="Champ'd Up Slam Down" />
    </div>
    <div class="padded instructions">
        <div class="inst" v-html="content?.content" />
        <div class="images" v-html="sections?.imgCardTypes?.content" />
        <div class="inst" v-html="sections?.setup?.content" />
        <div class="images" v-html="sections?.imgDiagram?.content" />
        <div class="inst" v-html="sections?.playing?.content" />
        <div class="images" v-html="sections?.imgCardDiagram?.content" />
        <div class="inst" v-html="sections?.endingTurn?.content" />
        <div class="images" v-html="sections?.imgCards?.content" />
        <div class="inst" v-html="sections?.winning?.content" />
        <div class="images" v-html="sections?.imgExhausted?.content" />
        <div class="inst" v-html="sections?.recruiting?.content" />
        <div class="images" v-html="sections?.imgThreeCards?.content" />
        <div class="inst" v-html="sections?.trainers?.content" />
        <div class="images" v-html="sections?.imgTrainer?.content" />
    </div>
</div>
</template>

<script lang="ts">
import type { NuxtApp } from '#app'
import { oembed } from '$services/embed'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    fetchKey() {
        const { $i18n } = useNuxtApp()
        return `champd-up-slam-down-rules-${$i18n.locale.value}`
    },
    data() {
        return {
            isError: false,
            isLoading: false,
            content: <JBGWeb.GenericContent|null> null,
            sections: <{[key: string]: JBGWeb.GenericSection}> {}
        }
    },
    async asyncData({ $cms, $i18n }: NuxtApp) {
        const data = {
            isError: false,
            isLoading: false,
            content: <JBGWeb.GenericContent|null> null,
            sections: <{[key: string]: JBGWeb.GenericSection}> {}
        }

        try {
            const currentLocale = $i18n.locale.value
            const localeContentKey = (currentLocale !== 'en')
                ? `champd-up-slam-down-rules-${currentLocale}`
                : 'champd-up-slam-down-rules'

            const content = await $cms.fetchOne<JBGWeb.GenericContent>('generic-contents', localeContentKey, {
                populate: ['sections'],
                locale: $i18n.locale.value
            })

            data.content = content
            if (data.content && data.content.sections) {
                data.sections = data.content.sections.reduce((
                    map: {[key: string]: JBGWeb.GenericSection},
                    obj: JBGWeb.GenericSection
                ) => {
                    map[obj.key] = obj
                    return map
                }, {})
            }

            data.sections.howtoplay.content = await oembed(data.sections.howtoplay.content)
        } catch (error) {
            console.warn(error)
            data.isError = true
        } finally {
            data.isLoading = false
        }

        return data
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    head({ $i18n }) {
        return {
            title: this.content?.title,
            meta: [
                {
                    name: 'description',
                    content: this.content?.seo?.metaDescription
                }
            ]
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.slamdown {
    .hero {
        text-align: center;
        background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url('/images/slamdown/slamdown-bg.jpg');
        background-position: middle center;

        min-height: 450px;
        max-height: 500px;

        padding: 100px 0 0 0;
        margin: -138px 0 0 0;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 600px;
            @include mq-small-and-less {
                width: 75%
            }
        }
    }

    .instructions {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include mq-medium-and-less {
            grid-template-columns: 1fr;
        }

        .inst {
            margin-right: 20px;

            :deep(blockquote) {
                border-radius: 15px;
                background-color: var(--neutral-600);
                padding: 15px;
                margin: 0;
                p {
                    margin: 0;
                }
            }
        }

        .images {
            background-color: var(--neutral-700);

            :deep(img) {
                max-width: 100%;
            }

            @include mq-medium-and-less {
                margin-top: 20px;
                background-color: unset;
            }
        }
    }
}
</style>
